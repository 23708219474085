import React from 'react';
import styled from 'styled-components';
import { pxToEm, HeadingWithIcon, Breakpoints } from '@asurion-hub-web/ui';
import devicesIcon from '@asurion-hub-web/assets/images/icons/home-page-devices-icon.svg';
import {
  useDeviceCategoriesState,
  useDevicesState,
} from '@asurion-hub-web/devices';
import {
  deviceCategoryMap,
  withSuspenseAndErrorBoundary,
  Device,
  GatewayDeviceCategory,
} from '@asurion-hub-web/devices-base';
import { useUserDevicesState } from '@asurion-hub-web/user-devices';
import { useAnalytics, withAnalyticOnView } from 'react-shisell';
import { flow } from 'fp-ts/lib/function';
import { useDeviceConnectionsCtaProvider } from '../hooks';
import { shuffleArray } from '@asurion-hub-web/user-add-device-flow';
import addIcon from '@asurion-hub-web/assets/images/icons/add-2.svg';

const CategoryAddIcon = styled.div<{ iconUrl: string }>`
  background-image: url(${(props) => props.iconUrl});
  position: absolute;
  width: 24px;
  height: 24px;
  top: 3px;
  left: 3px;
`;

const UserDeviceContainer = styled.div`
  margin: 1em 0.5em;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 120px;
  min-height: 120px;
  flex-shrink: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #d5d6da;
  border-radius: 4px;
  padding: 1em 0;

  :first-child {
    margin-left: 0;
  }
  :last-child {
    border: none;
  }
`;

const UserDevicesContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    width: 100%;
  }

  display: flex;
  flex-direction: row;
  margin-left: 1.25em;
  overflow-x: auto;
  width: calc(100vw - 1.25em);
  font-family: Apercu-Regular-Pro;
`;

const DeviceCategoriesContainer = styled.div`
  @media ${Breakpoints.mobileBig} {
    width: 100%;
  }

  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-left: 1.25em;
  width: calc(100vw - 1.25em);
  font-family: Apercu-Regular-Pro;
`;

const DeviceCategoryContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0.5em;
  cursor: pointer;
  width: ${pxToEm(120)};
  min-height: ${pxToEm(120)};
  flex-shrink: 0;
  text-align: center;
  justify-content: center;
  border: 1px solid #d5d6da;
  border-radius: 4px;
  padding: 1em 0;

  :first-child {
    margin-left: 0;
  }
`;

const EmptyCategoryContainer = styled(DeviceCategoryContainer)`
  cursor: default;
  border: none;
  width: ${pxToEm(24)};
`;

const CategoryIcon = styled.div<{ image: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  margin-bottom: 0.5em;
`;

const HeadingCount = styled.span`
  color: gray;
`;

const UserDeviceText = styled.div`
  text-align: center;
  max-height: 59px;
  -webkit-line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  padding: 0 0.5em;
`;

export const UserDeviceDisplayModule: React.FC<{
  device: Device;
}> = ({ device }) => {
  const enrichedDeviceCategory = deviceCategoryMap[device.category];

  return (
    <UserDeviceContainer>
      <CategoryIcon image={enrichedDeviceCategory.icon} />
      <UserDeviceText>{`${device.make} ${device.model}`}</UserDeviceText>
    </UserDeviceContainer>
  );
};

export type UserDevicesDisplayModuleProps = {
  onCategoryClick: (category: string) => void;
};

const UserDevicesDisplayModule: React.FC<UserDevicesDisplayModuleProps> = (
  props
) => {
  const deviceCategories: string[] = shuffleArray(useDeviceCategoriesState());
  const userDevices = useUserDevicesState();
  const devices = userDevices.length > 0 ? useDevicesState() : []; // eslint-disable-line react-hooks/rules-of-hooks
  const filteredDevices = devices.filter((d) =>
    userDevices.find((uD) => uD.deviceId === d.id)
  );
  const SelectDeviceConnectionsCta = useDeviceConnectionsCtaProvider();
  const analytics = useAnalytics();
  const { onCategoryClick } = props;
  const onCategoryClickCallback = React.useCallback(
    (deviceCategory: string, index: number) => {
      analytics.dispatcher
        .withExtra('ClickId', 'DeviceCategoryHomepageClick')
        .withExtra('DeviceCategoryIndex', index)
        .withExtra('DeviceCategory', deviceCategory)
        .dispatch('Click');

      return onCategoryClick(deviceCategory);
    },
    [onCategoryClick, analytics]
  );

  return (
    <>
      <HeadingWithIcon iconSrc={devicesIcon}>
        My devices&nbsp;
        <HeadingCount data-testid="user-devices-heading-count">
          ({filteredDevices.length})
        </HeadingCount>
      </HeadingWithIcon>
      <UserDevicesContainer>
        {userDevices &&
          userDevices.map((userDevice) => {
            const device = filteredDevices.find(
              (device) => device.id === userDevice.deviceId
            );
            if (!device) {
              return null;
            }
            return (
              <UserDeviceDisplayModule
                key={userDevice.userDeviceId}
                device={device}
              />
            );
          })}
        {filteredDevices.length > 0 && <UserDeviceContainer />}
      </UserDevicesContainer>
      {SelectDeviceConnectionsCta}
      <DeviceCategoriesContainer>
        {deviceCategories.map((deviceCategory, i) => (
          // eslint-disable-next-line styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions
          <DeviceCategoryContainer
            key={deviceCategory}
            onClick={() => onCategoryClickCallback(deviceCategory, i)}
          >
            <CategoryAddIcon iconUrl={addIcon} />
            <CategoryIcon
              image={
                deviceCategoryMap[deviceCategory as GatewayDeviceCategory].icon
              }
            />
            <UserDeviceText>
              {deviceCategoryMap[deviceCategory as GatewayDeviceCategory].text}
            </UserDeviceText>
          </DeviceCategoryContainer>
        ))}
        {deviceCategories.length > 0 && <EmptyCategoryContainer />}
      </DeviceCategoriesContainer>
    </>
  );
};

export default flow(
  withAnalyticOnView<UserDevicesDisplayModuleProps>({
    analyticName: 'SectionView',
    mapPropsToExtras: () => ({
      Section: 'UserDevicesDisplayModule',
      DevicesVersion: 'v1',
    }),
  }),
  withSuspenseAndErrorBoundary
)(UserDevicesDisplayModule);
