import { Document } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';

export type BenefitHighlight = {
  text: string;
  popoverId: string;
  popoverText: Document;
  image?: Asset;
  articleId?: string;
};

export type ProtectionHighlight = {
  iconSrc: string;
  text: string;
};

export enum ServicePlanType {
  Mobility = 'Mobility',
  ConnectedHome = 'ConnectedHome',
}

export type ServicePlan = {
  id: string;
  type: ServicePlanType;
  name: string;
  checkEligibilityUrl?: string;
  claimsUrl?: string;
  onboardingUrl?: string;
  infoUrl?: string;
  benefitHighlights: BenefitHighlight[];
  protectionHighlights: ProtectionHighlight[];
  partner: string;
  includesSupport: boolean;
  tagline?: string;
  logo?: Asset;
  backgroundImage?: Asset;
};

type ProtectionHighlightEntry = Entry<
  Omit<ProtectionHighlight, 'iconSrc'> & {
    icon: Asset;
  }
>;

export type ServicePlanEntry = Omit<
  ServicePlan,
  'benefitHighlights' | 'protectionHighlights'
> & {
  benefitHighlights: Entry<BenefitHighlight>[];
  protectionHighlights: ProtectionHighlightEntry[];
};
