import { authClient } from '@asurion-hub-web/auth';
import { AuthenticatedUser } from '@asurion-hub/auth';
import { RouteBuilder } from '@asurion-hub-web/config';
import { useHistory } from 'react-router-dom';
import { logger } from '@asurion-hub/logging';
import { useAnalytics } from 'react-shisell';

enum RedirectType {
  ClaimDetails = 'claim_horizon_redirect',
}

const getRedirectUri = (params: Record<string, unknown>): null | string => {
  switch (params.redirectType) {
    case RedirectType.ClaimDetails:
      return params.claimId
        ? RouteBuilder.routeForClaimDetails(params.claimId as string)
        : null;
    default:
      return null;
  }
};

// see auth-state-redirect-web.md documentation about how to use
export const useStateRedirect = () => {
  const analytics = useAnalytics();
  const history = useHistory();
  const state = authClient.getState();

  // clear the state to avoid a redirect loop
  authClient.clearState();
  const dispatcher = analytics.dispatcher.withExtra(
    'ActivityType',
    'StateRedirect'
  );
  if (state) {
    const enhancedDispatcher = dispatcher.withExtra('State', state);

    try {
      const decoded = atob(state); // base64 url decode
      const parsed = JSON.parse(decoded) as Record<string, unknown>;
      const uri = getRedirectUri(parsed);
      if (uri) {
        enhancedDispatcher
          .withExtra('RedirectResult', 'Success')
          .dispatch('Activity');
        history.push(uri);
      } else {
        enhancedDispatcher
          .withExtra('RedirectResult', 'Failure')
          .dispatch('Activity');
      }
    } catch (err) {
      enhancedDispatcher
        .withExtra('RedirectResult', 'Error')
        .dispatch('Activity');
      logger.error('failed to parse authentication state', err, {
        state,
        AsurionId: AuthenticatedUser.get()?.asurionId,
      });
    }
  } else {
    dispatcher.withExtra('RedirectResult', 'NoState').dispatch('Activity');
  }
};
