import React from 'react';
import styled from 'styled-components';
import {
  Breakpoints,
  ContentPanes,
  HeadingWithIcon,
} from '@asurion-hub-web/ui';
import discoverIcon from '../assets/home-page-discover-icon.svg';
import { CARD_MARGINS } from '../constants';

const selectors = {
  cardsContainer: '> div',
  card: '[class^="cardContainer-"]',
};

const ArticleListContainer = styled(ContentPanes)`
  ${selectors.card} {
    margin: 0;
    margin-bottom: ${CARD_MARGINS[1]}px;
    max-width: none;
    width: 100%;
    flex-basis: auto;
    border-radius: 8px;
    overflow: hidden;
  }
  @media ${Breakpoints.mobileBig} {
    ${selectors.cardsContainer} {
      padding: 0;
    }
    ${selectors.card} {
      margin: 0;
      box-sizing: border-box;
      width: calc(50% - ${CARD_MARGINS[0] / 2}px);
      margin-left: ${CARD_MARGINS[0]}px;
      margin-bottom: ${CARD_MARGINS[1]}px;
      :nth-child(odd) {
        margin-left: 0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  > div {
    box-sizing: border-box;
    padding-top: 12px;
  }
  > div > div {
    box-sizing: border-box;
    padding-top: 10px !important;
  }
`;

type DiscoverSectionProps = {
  parentPage: string;
  deviceFriendlyName: string;
  partner: string;
  articlesShown: number;
};

const DiscoverSection: React.FC<DiscoverSectionProps> = ({
  parentPage,
  deviceFriendlyName,
  partner,
  articlesShown,
}) => {
  return (
    <>
      <div id="discover">
        <HeadingWithIcon iconSrc={discoverIcon}>Discover</HeadingWithIcon>
        <ContentContainer>
          <ArticleListContainer
            articleContext={parentPage}
            partner={partner}
            limit={articlesShown}
            deviceFriendlyName={deviceFriendlyName}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default DiscoverSection;
