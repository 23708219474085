import { useAllClaims } from '@asurion-hub-web/enrollment';
import { Plan } from '@asurion-hub/enrollment';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import React from 'react';
import { HeadingWithIcon } from '@asurion-hub-web/ui';
import { mapPlansToPlanDevices } from '../hocs';
import phoneIcon from '@asurion-hub-web/assets/images/icons/mobile-phone-1.svg';
import { Devices } from './Devices';

type DevicesSectionProps = {
  plans?: Plan[] | null;
  onInfoClick: () => void;
  onClaimClick: (claimId: string) => void;
};


const DevicesSection: React.FC<DevicesSectionProps> = ({
  plans,
  onInfoClick,
  onClaimClick
}) => {
  const [partnerConfig] = usePartnerConfig();
  const { claims } = useAllClaims(partnerConfig.id);

  return (
    <>
      <HeadingWithIcon iconSrc={phoneIcon}>My devices</HeadingWithIcon>
      <br />
        {plans && (
          <Devices
            onInfoClick={onInfoClick}
            onClaimClick={onClaimClick}
            planDevices={mapPlansToPlanDevices(plans, claims || [])}
            claims={claims ? claims : []}
          />
        )}
    </>
  );
};

export default DevicesSection;
