import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line
const logoWhite = require('@npmsoluto/asurion-design-assets-asurion-black')
  .logoWhite.default;

const FooterContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 1em 0;
  background: black;
  flex-direction: column;
  align-items: flex-start;
`;

const RowContainer = styled.div`
  display: flex;
  height: 3rem;
  flex-direction: row;
`;

const AsurionLogo = styled.img`
  height: 100%;
  width: auto;
`;

const Footer: React.FC = () => (
  <FooterContainer>
    <RowContainer>
      <AsurionLogo
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        src={logoWhite}
        alt="asurion logo"
        data-test-id="footer-logo"
      />
    </RowContainer>
  </FooterContainer>
);

export default Footer;
