import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { PartnerConfig, Partner } from '@asurion-hub/partner-config';
import { setPartner as baseSetPartner, partnerConfig$ } from './subject';
import { PARTNER_STORAGE_KEY } from './constants';

export const usePartnerConfig = (): [
  PartnerConfig,
  (partner: Partner) => void
] => {
  const [storedPartner, setStoredPartner] = useLocalStorage(
    PARTNER_STORAGE_KEY
  );
  const [partnerConfig, setPartnerConfig] = useState(partnerConfig$.value);

  useEffect(() => {
    const subscription = partnerConfig$.subscribe((config) => {
      setPartnerConfig(config);
    });
    if (storedPartner && storedPartner !== partnerConfig.id) {
      baseSetPartner(storedPartner as Partner);
    }
    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setPartner = (partnerId: Partner) => {
    baseSetPartner(partnerId);
    setStoredPartner(partnerId);
  };

  return [partnerConfig, setPartner];
};
