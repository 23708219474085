import { CACHE_TIME_DEFAULT } from '@asurion-hub-web/react-utils-web';
import { useQuery } from 'react-query';
import { useServicePlanCache, ServicePlan } from '@asurion-hub/enrollment';
import { usePlans } from './usePlans';

export type ServicePlansState = {
  servicePlansError: Error | null,
  servicePlansAreFetching: boolean,
  servicePlans?: ServicePlan[],
}

export const useAccountServicePlans = (): ServicePlansState => {
  const { plans } = usePlans();
  const productIds = plans?.map((p) => p.productId) || [];
  const servicePlanCache = useServicePlanCache();

  const { error, data, isFetching } = useQuery(
    `servicePlans_${productIds.sort().join(',')}`,
    () => {
      return Promise.all(
        productIds.filter((id) => !!id).map((id) => servicePlanCache.get(id))
      );
    },
    {
      refetchOnMount: false,
      staleTime: CACHE_TIME_DEFAULT,
      cacheTime: CACHE_TIME_DEFAULT,
      enabled: productIds.length > 0,
    }
  );
  return {
    servicePlansError: error,
    servicePlansAreFetching: isFetching,
    servicePlans: data,
  };
};
