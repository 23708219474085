import React, { useState } from 'react';
import { PlanDevice } from '../hocs';
import styled from 'styled-components';
import {
  DynamicLink,
  formatPhone,
  pxToEm,
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX
} from '@asurion-hub-web/ui';
import MobileDeviceImage from './MobileDeviceImage';
import { Claim } from '@soluto-private/account-api-client';
import { getResponsiveImageUrlFromAssetAttributes } from '@asurion-hub/enrollment';

const breakpoint = BREAK_POINTS_DIMENSIONS_VALUES_IN_PX.mobileBig;

type DevicesProps = {
  planDevices: PlanDevice[];
  onInfoClick: (assetId: string) => void;
  onClaimClick: (claimId: string) => void;
  claims: Claim[];
};

const DeviceContainer = styled.div<{ oneButton: boolean }>`
  display: block;
  height: ${pxToEm(80)};
  margin-top: ${pxToEm(20)};
  margin-left: ${pxToEm(30)};
  margin-bottom: ${pxToEm(24)};
  margin-right: ${pxToEm(20)};
  @media (max-width: ${breakpoint}px) {
    ${(props) =>
      props.oneButton ? `margin-bottom: ${pxToEm(24)};` : `margin-bottom: ${pxToEm(-30)};`}
    height: ${pxToEm(200)};
  }
`;

const CustomLine = styled.div`
  border: 0;
  height: ${pxToEm(1)};
  background: #000;
  opacity: 0.1;
`;

const DeviceImageContainer = styled(MobileDeviceImage)`
  width: ${pxToEm(61)};
  max-width: ${pxToEm(140)};
  height: ${pxToEm(91)};
  max-height: initial;
  margin-bottom: ${pxToEm(8)};
  float: left;
`;

const TextContainer = styled.div`
  margin-left: 80px;
`;

const StatusBanner = styled.div<{ contentVisible: boolean }>`
  ${(props) => (props.contentVisible ? 'background-color: #37E7A7;' : '')}
  width: fit-content;
  padding: ${pxToEm(2)} ${pxToEm(3)};
  font-family: 'Apercu-Regular-Pro';
  border-radius: ${pxToEm(2)};
  font-size: ${pxToEm(14)};
  line-height: ${pxToEm(14)};
  min-height: ${pxToEm(14)};
  margin-bottom: ${pxToEm(10)};
`;

interface ButtonProps {
  actionId: string;
}

interface DeviceDetailsButtonProps {
  actionId: string;
  isOneButton: boolean;
}

const TrackClaimButton = styled(DynamicLink)<ButtonProps>`
  display: block;
  border: ${pxToEm(1)} solid black;
  font-size: ${pxToEm(14)};
  width: ${pxToEm(200)};
  height: ${pxToEm(40)};
  background: black;
  border-radius: ${pxToEm(40)};
  padding: ${pxToEm(8)};
  color: white;
  z-index: 1;
  text-decoration: none;
  margin-bottom: ${pxToEm(10)};
  @media (max-width: ${breakpoint}px) {
    width: 100%;
  }
`;

const DeviceDetailButton = styled(DynamicLink)<DeviceDetailsButtonProps>`
  display: block;
  border: 1px solid black;
  font-size: ${pxToEm(14)};
  width: ${pxToEm(200)};
  height: ${pxToEm(40)};
  background: white;
  border-radius: ${pxToEm(40)};
  padding: ${pxToEm(8)};
  color: black;
  z-index: 1;
  text-decoration: none;
  margin-bottom: ${pxToEm(20)};
  @media (max-width: ${breakpoint}px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div<{ oneButton: boolean }>`
  float: right;
  ${(props) => (props.oneButton ? `margin-top: ${pxToEm(-80)};` : `margin-top: ${pxToEm(-42)};`)}
  @media (max-width: ${breakpoint}px) {
    float: none;
    margin-top: ${pxToEm(40)};
  }
`;

const ModelText = styled.div`
  font-size: ${pxToEm(16)};
  color: #6e767d;
`;

const PhoneNumberText = styled.div`
  font-size: ${pxToEm(20)};
  display: flex;
  width: ${pxToEm(180)};
`;

const ShowButton = styled.button`
    width: 100%;
    margin: auto;
    text-align: center;
    background-color: white;
    margin-left: ${pxToEm(20)};
    margin-bottom: ${pxToEm(20)};
    border: none;
`;

const getClaimId = (planDevice: PlanDevice, claims: Claim[]): string => {
  const claim = claims.filter(
    (claim) => claim.subId === planDevice.subscriberId
  );
  if (claim.length > 0) {
    return claim[0].claimId;
  }
  return '';
};

const sortPlanDevices = (planDevices: PlanDevice[]): PlanDevice[] => {
  return [...planDevices].sort((a, b) => a.hasClaimInProgress && !b.hasClaimInProgress ? -1 : 0);
};


const getDeviceSection = (planDevice: PlanDevice, index: number, claims: Claim[], onInfoClick: (assetId: string) => void, onClaimClick: (claimId: string) => void) => {
  return (
    <>
    <DeviceContainer
      oneButton={planDevice.hasClaimInProgress}
      key={index}
    >
      <DeviceImageContainer
        model={planDevice.model}
        responsiveImageUrl={getResponsiveImageUrlFromAssetAttributes(
          planDevice.assetCatalogAttributes
        ) as string}
      />

      <TextContainer>
        <br />
        {planDevice.hasClaimInProgress && (
          <StatusBanner
            contentVisible={planDevice.hasClaimInProgress}
          >
            {planDevice.hasClaimInProgress && 'Claim in progress'}
          </StatusBanner>
        )}
        <PhoneNumberText>
          <b>{formatPhone(planDevice.mobileDeviceNumber!)}</b>
        </PhoneNumberText>
        <ModelText>{planDevice.model}</ModelText>
      </TextContainer>
      <ButtonContainer oneButton={planDevice.hasClaimInProgress}>
        {planDevice.hasClaimInProgress && (
          <TrackClaimButton
            actionId="trackMyClaim_authHomePage"
            onClick={() => {
              onClaimClick(
                getClaimId(planDevice, claims)
              );
            }}
          >
            Track my claim
          </TrackClaimButton>
        )}
        <DeviceDetailButton
          isOneButton={planDevice.hasClaimInProgress}
          actionId="deviceAndPlanDetail_authHomePage"
          onClick={() => {
            onInfoClick(planDevice.assetId!);
          }}
        >
          Device and plan details
        </DeviceDetailButton>
      </ButtonContainer>
    </DeviceContainer>
    <CustomLine>
      <hr />
    </CustomLine>
  </>
  )
}

export const Devices: React.FC<DevicesProps> = (props) => {
  const { planDevices } = props;
  const [showMoreActive, setShowMoreActive] = useState(false);

  return (
    <>
      {sortPlanDevices(planDevices).map((planDevice, index) => {
        if (index < 4) {
          return getDeviceSection(planDevice, index, props.claims, props.onInfoClick, props.onClaimClick);
        }
        return <></>;
      })}
      {showMoreActive && planDevices.length > 4 && (
        <>
          {sortPlanDevices(planDevices).map((planDevice, index) => {
            if (index >= 4) {
              return getDeviceSection(planDevice, index, props.claims, props.onInfoClick, props.onClaimClick);
            }
            return <></>;
          })}
        </>
      )}
      {!showMoreActive && planDevices.length > 4 && (
        <ShowButton onClick={() => setShowMoreActive(true)}>
          Show all ({planDevices.length}) ↓
        </ShowButton>
      )}
      {showMoreActive && planDevices.length > 4 && (
        <ShowButton onClick={() => setShowMoreActive(false)}>Show Less ↑</ShowButton>
      )}
    </>
  );
};
