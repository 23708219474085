import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeadingWithIcon, Breakpoints } from '@asurion-hub-web/ui';
import { Partner } from '@asurion-hub/partner-config';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import {
  PlanTile,
  ServicePlansState,
  useServicePlan,
} from '@asurion-hub-web/enrollment';
import { ServicePlan } from '@asurion-hub/enrollment';
import { CARD_MARGINS } from '../constants';
import protectionIcon from '../assets/protection.svg';

const breakpoint = Breakpoints.mobileBig;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
  max-width: 921px;
  @media ${breakpoint} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const IndividualCard = styled.div`
  margin-bottom: ${CARD_MARGINS[1]}px;
  width: 100%;
  @media ${breakpoint} {
    width: calc(50% - ${CARD_MARGINS[0] / 2}px);
    margin-left: ${CARD_MARGINS[0]}px;
    margin-bottom: ${CARD_MARGINS[1]}px;
    :nth-child(odd) {
      margin-left: 0;
    }
  }
`;

const HeaderContainer = styled.div`
  margin-bottom: 32px;
`;

const IndividualCardContainer: React.FC<{
  servicePlan: ServicePlan;
  isActive: boolean;
  buttonConfig?: {
    label: string;
    url: string
  }
}> = ({ servicePlan, isActive, buttonConfig }) => {
  return servicePlan ? (
    <IndividualCard>
      {servicePlan && (
        <PlanTile
          name={servicePlan.name}
          partner={servicePlan.partner as Partner}
          protectionType={servicePlan.type}
          isActive={isActive}
          isProtectionActive={true}
          backgroundImage={servicePlan.backgroundImage?.fields.file.url || ''}
          logo={servicePlan.logo?.fields.file.url || ''}
          tagline={servicePlan.tagline}
          addPlanButtonConfig={buttonConfig}
        />
      )}
    </IndividualCard>
  ) : null;
};

type PlanTileSectionProps = {
  servicePlansState: ServicePlansState;
};

const PlanTileSection: React.FC<PlanTileSectionProps> = (props) => {
  const [{ id: partnerId }] = usePartnerConfig();
  const [plansWithoutDuplicates, setPlansWithoutDuplicates] = useState<
    ServicePlan[]
  >([]);
  const { servicePlansState: { servicePlans } } = props;
  useEffect(() => {
    if (!servicePlans) {
      return;
    }
    const idsWithoutDuplicates = Array.from(
      new Set(servicePlans.map((sp) => sp.id))
    );
    const result = idsWithoutDuplicates.map(
      (id) => servicePlans.find((sp) => sp.id === id)!
    );
    setPlansWithoutDuplicates(result);
  }, [servicePlans]);
  const homePlan = useServicePlan('VPH');

  return (
    <>
      <HeaderContainer>
        <HeadingWithIcon iconSrc={protectionIcon}>
          My protection plans
        </HeadingWithIcon>
      </HeaderContainer>
      <CardContainer>
        {plansWithoutDuplicates.filter(plan => plan.partner === partnerId).map((servicePlan) => (
          <IndividualCardContainer
            servicePlan={servicePlan}
            key={servicePlan.id}
            isActive={true}
          />
        ))}
        {partnerId === 'verizon' && homePlan.servicePlan && (
          <IndividualCardContainer
            servicePlan={homePlan.servicePlan}
            isActive={false}
            buttonConfig={{
              label: 'Add home protection',
              url: homePlan.servicePlan.infoUrl || '',
            }}
          />
        )}
      </CardContainer>
    </>
  );
};

export default PlanTileSection;
