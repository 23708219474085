import * as React from 'react';
import styled from 'styled-components';
import { pxToEm } from '../../utils';
import repairReplaceIcon from '@asurion-hub-web/assets/images/icons/repairReplaceIcon.svg';
import getSupportIcon from '@asurion-hub-web/assets/images/icons/getSupportIcon.svg';
import photosIcon from '@asurion-hub-web/assets/images/icons/photosIcon.svg';
import discoverTipsIcon from '@asurion-hub-web/assets/images/icons/discoverTipsIcon.svg';
import { useTweekValue } from 'react-tweek';
import { DynamicLink } from '@asurion-hub-web/ui';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';

const Banner = styled.div`
  width: 100%;
  display: block;
  color: white;
  margin-top: ${pxToEm(20)};
  text-align: center;
  margin-bottom: ${pxToEm(20)};
`;

const Text = styled.div`
  margin-bottom: ${pxToEm(12)};
  line-height: 1.3;
  color: black;
  font-size: ${pxToEm(32)};
  font-weight: 900;
  margin: auto;
  display: block;
`;

const ButtonText = styled.div`
  color: black;
  font-size: ${pxToEm(14)};
  width: ${pxToEm(130)};
  display: block;
  margin: auto;
  font-family: 'Apercu-Light-Pro', sans-serif;
`;

const SubText = styled(Text)`
  font-size: ${pxToEm(32)};
  font-weight: 100;
`;

const Circle = styled.div`
  background: #f0f0f5;
  border-radius: ${pxToEm(20)};
  font-size: ${pxToEm(25)};
  text-align: center;
  border-radius: ${pxToEm(9999)};
  display: inline-block;
  padding: ${pxToEm(10)};
`;

const ButtonContainer = styled.div<{
  twoButtons: boolean;
}>`
  display: block;
  gap: ${pxToEm(70)};
  width: ${pxToEm(300)};
  margin: auto;
`;

const Icon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
`;

const Button = styled.button`
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: ${pxToEm(120)};
  border: none;
  background: white;
`;

export const getIntroHeader = (): string => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return 'Good Morning!';
  } else if (curHr < 18) {
    return 'Good Afternoon!';
  }
  return 'Good Evening!';
};

type QuickLinksProps = {
  showSupportButton: boolean;
};

export const QuickLinks: React.FunctionComponent<QuickLinksProps> = ({
  showSupportButton,
}) => {
  const photosLink = useTweekValue('asurion_hub/photos/photolink', '');
  const photosEnabled = useTweekValue(
    'asurion_hub/photos/photosenabled',
    false
  );
  const discoveryEnabled = useTweekValue(
    'asurion_hub/discover/discover_enabled',
    false
  );
  const [partnerConfig] = usePartnerConfig();

  return (
    <Banner>
      <br />
      <br />
      <Text>{getIntroHeader()}</Text>
      <SubText>How can we help you today?</SubText>
      <br />
      <ButtonContainer twoButtons={photosEnabled}>
        <DynamicLink
          to={`https://www.phoneclaim.com/${partnerConfig.id.toLowerCase()}/`}
          data-testid="claimButton"
          actionId="startClaim"
        >
          <Button>
            <Circle>
              <Icon src={repairReplaceIcon} alt={'Claims Icon'} />
            </Circle>
            <ButtonText>Repair and replace</ButtonText>
          </Button>
        </DynamicLink>
        {showSupportButton && (
          <DynamicLink
            onClick={async () => {
              await window.AE_SDK.triggerMessagingOverlay();
            }}
            actionId="getSupport"
          >
            <Button>
              <Circle>
                <Icon src={getSupportIcon} alt={'Support Icon'} />
              </Circle>
              <ButtonText>Get support</ButtonText>
            </Button>
          </DynamicLink>
        )}
        {photosEnabled && (
          <DynamicLink data-testid="photoButton" to={photosLink} actionId="viewPhotos">
            <Button>
              <Circle>
                <Icon src={photosIcon} alt={'Photos Icon'} />
              </Circle>
              <ButtonText>View photos</ButtonText>
            </Button>
          </DynamicLink>
        )}
        {discoveryEnabled && (
          <DynamicLink actionId="discoverTips">
          <a href="#discover">
            <Button>
              <Circle>
                <Icon src={discoverTipsIcon} alt={'Discover Tips Icon'} />
              </Circle>
              <ButtonText>Discover tips</ButtonText>
            </Button>
          </a>
          </DynamicLink>

        )}
      </ButtonContainer>
    </Banner>
  );
};
