import React from 'react';
import { Header } from '@asurion-hub-web/header';
import {
  AlertBannerNotifications,
  ToastNotifications,
} from '@asurion-hub-web/notifications';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import {
  NoDetailsFoundPage,
  Footer,
  SessionTimeOutPage,
  CatchAllErrorPage,
} from '@asurion-hub-web/ui';
import { DeviceDetailsPage } from '@asurion-hub-web/user-plan-devices';
import CollectMdnPage from './account/pages/CollectMdn/CollectMdnContainer';
import { InfoNotCorrectMultiPlan } from './account/pages/InfoNotCorrect';
import Logout from './account/pages/Logout';
import VerifyMdnPage from './account/pages/VerifyMdn/VerifyMdnContainer';
import { ClaimDetailsPage } from './account/pages/ClaimDetailsPage';
import ContentContainerPage from './content/pages/ContentContainerPage';
import NotFoundPage from './error-pages/NotFoundPage';
import { PrivateRoute, PublicRoute } from './hoc';

import Root from './Root';
import { useDeviceRoutes } from '@asurion-hub-web/user-add-device-flow';
import { RedirectToClaimDetails } from './account/pages/ClaimDetailsPage/ClaimDetailsPageByPlanContainer';
import {
  INFO_NOT_CORRECT_ROUTE_BY_PLAN,
  DETAILS_NOT_FOUND_ROUTE,
  SESSION_TIMEOUT,
  CATCH_ALL_ERROR,
  DEVICE_DETAILS_ROUTE,
  CLAIM_DETAILS_ROUTE,
  CLAIM_DETAILS_BY_PLAN_ROUTE,
  ARTICLE_ROUTE,
  VERSION_ROUTE,
  LOGOUT_ROUTE,
  COLLECT_MDN_ROUTE,
  VERIFY_MDN_ROUTE,
  ROOT_ROUTE,
  MOBILE_ARTICLE_ROUTE,
} from '@asurion-hub-web/config';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const MainContentContainer = styled.div`
  flex: 1 0 auto;
`;

const HeaderContainer = styled.div`
  flex: none;
`;

const FooterContainer = styled.div`
  flex: none;
`;

export default function Routes() {
  const deviceRoutes = useDeviceRoutes();
  return (
    <Router>
      <PageContainer>
        <ToastNotifications />
        <Switch>
          <PublicRoute
            path={`${MOBILE_ARTICLE_ROUTE}/:articleId`}
            Component={ContentContainerPage}
          />
          <Route>
            <HeaderContainer>
              <Header />
              <AlertBannerNotifications />
            </HeaderContainer>
            <MainContentContainer>
              <Switch>
                {deviceRoutes.map((route, routeI) => (
                  <PrivateRoute
                    key={routeI}
                    path={route.path}
                    Component={route.Component}
                  />
                ))}
                <PrivateRoute
                  path={INFO_NOT_CORRECT_ROUTE_BY_PLAN}
                  Component={InfoNotCorrectMultiPlan}
                />
                <PrivateRoute
                  path={DETAILS_NOT_FOUND_ROUTE}
                  Component={NoDetailsFoundPage}
                  mdnVerify={false}
                />
                <PublicRoute
                  path={SESSION_TIMEOUT}
                  Component={SessionTimeOutPage}
                />
                <PublicRoute
                  path={CATCH_ALL_ERROR}
                  Component={CatchAllErrorPage}
                />
                <PrivateRoute
                  path={DEVICE_DETAILS_ROUTE}
                  exact={true}
                  Component={DeviceDetailsPage}
                />
                <PrivateRoute
                  path={CLAIM_DETAILS_ROUTE}
                  exact={true}
                  Component={ClaimDetailsPage}
                />
                <PrivateRoute
                  path={CLAIM_DETAILS_BY_PLAN_ROUTE}
                  exact={true}
                  Component={RedirectToClaimDetails}
                />
                <PublicRoute
                  path={`${ARTICLE_ROUTE}/:articleId`}
                  Component={ContentContainerPage}
                />
                <PublicRoute
                  path={VERSION_ROUTE}
                  Component={() => <div>{process.env.VERSION}</div>}
                />
                <PublicRoute path={LOGOUT_ROUTE} Component={Logout} />
                <PrivateRoute
                  path={COLLECT_MDN_ROUTE}
                  Component={CollectMdnPage}
                  mdnVerify={false}
                />
                <PrivateRoute
                  path={VERIFY_MDN_ROUTE}
                  Component={VerifyMdnPage}
                  mdnVerify={false}
                />
                <PublicRoute path={ROOT_ROUTE} Component={Root} exact={true} />
                <Route component={NotFoundPage} />,
              </Switch>
            </MainContentContainer>
            <FooterContainer>
              <Footer />
            </FooterContainer>
          </Route>
        </Switch>
      </PageContainer>
    </Router>
  );
}
