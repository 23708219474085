// eslint-disable
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTweekValue } from 'react-tweek';
import styled from 'styled-components';
import {
  Breakpoints,
  LoadingOverlay,
  QuickLinks,
} from '@asurion-hub-web/ui';
import {
  ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
  RouteBuilder as DevicesRouteBuilder,
} from '@asurion-hub-web/user-add-device-flow';
import { UserDevicesDisplayModule } from '@asurion-hub-web/user-devices-ui';
import { DevicesSection } from '@asurion-hub-web/user-plan-devices';
import { usePartnerConfig } from '@asurion-hub-web/partner-config-react';
import DiscoverSection from './components/DiscoverSection';
import PlanTileSection from './components/PlanTileSection';
import { DETAILS_NOT_FOUND_ROUTE, RouteBuilder } from '@asurion-hub-web/config';
import { useAccountIncludesSupport, usePlans, ServicePlansState } from '@asurion-hub-web/enrollment';

const breakpoint = Breakpoints.tablet;

const HomePageContainer = styled.div`
  width: 96vw;
  max-width: 922px;
  margin: 0 auto;
`;

const BannerContainer = styled.div`
  max-width: 96vw;
  margin: 0 auto;
  @media ${breakpoint} {
    max-width: 80%;
  }
`;

type AuthenticatedHomePageProps = {
  friendlyPageName?: string;
  servicePlansState: ServicePlansState;
};

const AuthenticatedHomePage: React.FC<AuthenticatedHomePageProps> = ({
  servicePlansState,
  friendlyPageName = 'Home',
}) => {
  const { plans, plansError, plansAreFetching } = usePlans();
  const pageIsLoading = plansAreFetching || !!plansError;
  const history = useHistory();
  const [partnerConfig] = usePartnerConfig();
  const accountSupport = useAccountIncludesSupport();

  useEffect(() => {
    if (plansError) {
      history.push(DETAILS_NOT_FOUND_ROUTE);
    }
  }, [plansError, history]);

  const navigateToDeviceDetails = (assetId?: string) => {
    history.push(RouteBuilder.routeForDeviceDetails(assetId));
  };

  const navigateToClaimDetails = (claimId: string) => {
    history.push(RouteBuilder.routeForClaimDetails(claimId));
  }

  const navigateToAddADevicePage = (category: string) => {
    history.push(DevicesRouteBuilder.routeForSelectDevice(category), {
      backText: friendlyPageName,
    });
  };

  const addDevicesFeatureFlag = useTweekValue(
    ADD_DEVICES_FLOW_V1_FEATURE_FLAG,
    false
  );

  return (
    <HomePageContainer>
      <LoadingOverlay isFullPage={true} isVisible={pageIsLoading} />
      <BannerContainer>
        <QuickLinks showSupportButton={accountSupport.accountIncludesSupport ? accountSupport.accountIncludesSupport : false} />
      </BannerContainer>
      <DevicesSection plans={plans} onInfoClick={navigateToDeviceDetails} onClaimClick={navigateToClaimDetails} />
      {addDevicesFeatureFlag && (
        <UserDevicesDisplayModule onCategoryClick={navigateToAddADevicePage} />
      )}
      <PlanTileSection servicePlansState={servicePlansState} />
      {!pageIsLoading && (
        <DiscoverSection
          parentPage={friendlyPageName}
          deviceFriendlyName=""
          partner={partnerConfig.name}
          articlesShown={999}
        />
      )}
    </HomePageContainer>
  );
};

export default AuthenticatedHomePage;
